<template>
  <base-route-card :route="route">
    <template v-slot:main>
      <price :pricing="route.pricing" />
    </template>
    <template v-slot:footer>
      <div
        class="text-neutral-lighter flex items-center justify-between gap-5 max-md:flex-col"
      >
        <div class="flex flex-col gap-2">
          <div class="flex max-md:mb-5">
            <icon icon="lit" class="text-primary-light mr-3 h-6 w-6"></icon>
            <span v-if="route.vehicle.type === 'before_coffin'">
              Transport avant et après mise en bière</span
            >
            <span v-if="route.vehicle.type === 'after_coffin'">
              Transport après mise en bière</span
            >
          </div>
          <div class="flex max-md:mb-5">
            <icon
              icon="dimensions"
              class="text-primary-light mr-3 h-6 w-6"
            ></icon>
            <span v-if="route.vehicle.is_standard_size">
              Supporte des tailles standards uniquement (&lt ou = à 195cm x
              60cm)</span
            >
            <span v-else>
              Supporte des tailles hors normes (> à 195cm x l60cm)</span
            >
          </div>
        </div>

        <div v-if="!hideBooking">
          <rounded-button
            v-if="route.is_reservation"
            class="bg-secondary max-md:px-24"
            disabled
            design="4"
          >
            EN ATTENTE DE VALIDATION
          </rounded-button>
          <rounded-button
            disabled
            v-if="route.is_mine"
            class="max-md:px-24"
            design="4"
          >
            VOTRE TRAJET
          </rounded-button>
          <rounded-button
            v-if="!route.is_reservation && !route.is_mine"
            class="max-md:px-24"
            design="3"
            @click="$emit('book', route)"
          >
            RESERVER
          </rounded-button>
        </div>
      </div>
    </template>
  </base-route-card>
</template>

<script setup>
import { defineProps } from "vue";
const { $api } = useNuxtApp();
const router = useRouter();
const props = defineProps({
  route: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  hideBooking: {
    type: Boolean,
    default: false,
  },
});
</script>
